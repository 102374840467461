.carousel-container {
    width: 95%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);    
    height: 100px; 
    padding:20px !important;
  }
  
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 30px;
    height: 30px;
  }
  
  .slick-prev {
    left: -35px;
  }
  
  .slick-next {
    right: -35px;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  a {
    font-size: 14px;
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  .read{
    font-size: 14px;
    bottom: 0;
    color: #007bff;
    text-decoration: none;
    display: flex;
    justify-content: end;
  }