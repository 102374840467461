.close{
    width: 100%;
    text-align: right;
    font-size: calc(2 * var(--basex));

    cursor: pointer;
}
.file-box{
    background-color: rgb(171, 202, 255);
    width:100%;
    padding: calc(2 * var(--basey)) calc(2 * var(--basex));
    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 10;
  }
  .file-upload-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    height: 400px;
    border: 3px solid rgb(63, 134, 255);
    margin: auto auto;
    border-radius: 25px;
    background-color: #FFF;
  }
  .file-upload-container h2{
    margin: 50px 0px 0px 0px;
    padding: 0px;
  }
  .file-upload-container p{
    margin: 10px 0px 50px 0px;
    padding: 0px;
  }
  .file-upload-box{
    border: 3px dotted rgb(63, 134, 255);
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 40%;
    align-items: center;
    border-radius: 25px;
    padding-top: 20px;
    cursor: pointer;
  }
  .file-upload-box img{
    display: flex;
    width: 30%;
  }
  .file-upload-box p{
    color: gray;
  }
  .file-input{
    opacity: 0;
  }
  .uploaded-file-name{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }
  .uploaded-file-name img{
    width: 30px;
    height: 20px;
    cursor: pointer;
  }
  .btn{
    border: 1px solid var(--green);
    background-color: var(--green);
    background-color: rgb(171, 202, 255);

    padding: calc(.8 * var(--basey)) 10px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    border: none;
}