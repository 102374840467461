.container {
    width: 100%;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));

    /* box-shadow: 0px 0px 15px 5px var(--green); */
    display: flex;
    background-color: #f5f5f5;
}

.left {
    width: 50%;

    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.leftContent {
    /* width: 80%; */
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* align-items: center; */
}

.heading {}

.heading h4 {
    width: fit-content;
    font-size: calc(4 * var(--basex));
    margin-bottom: calc(4 * var(--basey));
    border-bottom: 2px solid var(--green);
    ;
}

.details {}

.subheading {
    font-size: calc(2 * var(--basex));
    margin-bottom: calc(1.5 * var(--basey));
}

.details__imgs {}

.details__imgs>img {
    width: calc(24 * var(--basex));
    margin-right: calc(3 * var(--basex));
}

.right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right img {
    /* transform: scale(.1); */
    width: calc(40 * var(--basex));
}

@media only screen and (max-width:900px) {
    .container {
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .left {
        width: 80%;
        justify-content: center;
        align-items: center;
    }

    .right {
        width: 80%;
        justify-content: center;
        align-items: center;
    }

    .heading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width:600px) {
    .container {
        padding: calc(8 * var(--basey)) calc(2 * var(--basex)) calc(8 * var(--basey)) calc(2 * var(--basex));
    }

    .right img {
        margin-bottom: calc(4 * var(--basey));
    }

    .heading h4 {
        width: fit-content;
        font-size: calc(3 * var(--basex));
    }

    .details__imgs {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-left: 12%;
    }
}