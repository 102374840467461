.container{
    /* box-shadow: 0 5px 10px var(--green); */
    width:100%;
    padding: calc(2 * var(--basex));
    background-color: #F8F9FA;

}
.wrapper{
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    margin-bottom: calc(1.5 * var(--basex));
}
.content{
    min-width: 100%;
    
    text-align: center;
}
.content td{
    
}
.options{
    /* border-top: 1px solid rgba(0, 0, 0, 0.088); */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}

.left{
    font-size: calc(1.4 * var(--basex));
    padding-left: calc(2 * var(--basex));
}
.right{
    display: flex;
    font-size: calc(1.4 * var(--basex));
}
.right div{
    padding: calc(.5 * var(--basey)) calc(1 * var(--basex));
    background-color: var(--green);
    color: white;
    cursor: pointer;
}
.right div:first-child{
    margin-right: calc(2 * var(--basex));
}

.tag{
    border: 2px solid var(--green);
    color: var(--green);
    /* color: white; */
    /* background-color: var(--green); */
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(.2 * var(--basex));
}
.link{
    color: var(--green);
    text-decoration: underline;
    cursor: pointer;
}
table {
    border-collapse: collapse;
  }   
tr{
    height: 45px;
    border-bottom: 1px solid rgba(0,0,0,.2);
}
.member-heading,
.member-data{
    padding-right: 20px;
    word-wrap: break-word;
    max-width: 150px;
}
/* @media only screen and (max-width:600px){
    th{
        min-width: 200px !important;
    }
} */