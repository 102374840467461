.container{
    width: 100%;
    /* color: #E4E9F0; */
    /* background-color: var(--green); */
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    padding: calc(2 * var(--basex));
}
.content{

}
.icon{
    box-sizing: content-box;
    font-size: calc(2.7 * var(--basex));
    /* background-color: #E4E9F0; */
    /* background-color: var(--green); */
    color: #E4E9F0;
    /* padding: calc(1* var(--basey)) calc(1 * var(--basex)) calc(1* var(--basey)) calc(1 * var(--basex)); */
    width: calc(4.6 * var(--basex));
    height: calc(4.6 * var(--basex));
    /* border-radius: 10px; */
    margin-bottom: calc(2 * var(--basey));

    display: flex;
    justify-content: center;
    align-items: center;
}
.icon i{
    
}
.para{
    /* min-height: calc(20 * var(--basey)); */
    font-size: calc(1.2 * var(--basex));
    
}
.para p{

}
.heading{
    font-weight: 600;
    font-size: calc(2 * var(--basex));
    margin-bottom: calc(0 * var(--basey));
}
@media only screen  and (max-width:600px) {
    .icon{
        font-size: calc(4 * var(--basex));
        width: calc(6 * var(--basex));
    height: calc(6 * var(--basex));
    }
    .para{
        font-size: calc(2 * var(--basex));
    }
}