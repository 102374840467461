.container{
    width: 100%;
    /* height: 100%; */
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(35 * var(--basex));
    color: var(--green);
    background-color:white;
    border-radius: 10px;
    text-align: center;

}
.banner{
    width: 100%;
    overflow: hidden;
}
.banner img{
    width: 100%;
    height: auto;
}
.details{
    display: flex;
    padding: calc(3 * var(--basey)) calc(2 * var(--basex));
}
.form{
    display: flex;
    flex-direction: column;
}
.sol{
    margin-bottom: calc(3 * var(--basey));
}
.sol span{
    margin-right:calc(1 * var(--basex));
    font-size: calc(2.5 * var(--basex));
}
.sol span:last-child{
    margin-left: calc(1 * var(--basex));
}
.inp{

}
.inp p{
    font-size: calc(1.6 * var(--basex));
    margin-bottom: calc(1 * var(--basey));
}
.inp input{
    width: 100%;
    font-size: calc(1.8 * var(--basex));
    padding: calc(.4 * var(--basey)) 0;
    margin-bottom: calc(4 * var(--basey));
    /* border-radius: 5px; */
}
.pp{
    margin-bottom: calc(1.5 * var(--basey));
    font-size: calc(1.4 * var(--basex));
}
.pp span{
    font-weight: 500;
    color:var(--green);
}
.continue{
    margin-bottom: calc(2 * var(--basey));
    font-size: calc(1.8 * var(--basex));
    width: 100%;
    background-color: var(--green);
    color: white;
    padding: calc(1 * var(--basey)) 0;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.or{
    margin-bottom: calc(1 * var(--basey));
    display: flex;
    justify-content: center;
    align-items: center;
}
.or p{

}
.google{
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid var(--green);
    padding: calc(1 * var(--basey)) 0;
}
.google svg{
    margin-right: calc(1 * var(--basey));
}