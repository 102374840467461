.container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;

    padding: calc(5 * var(--basey)) calc(6 * var(--basex));

    background-color: #eaeaea;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.headings {
    height: 5vh;
}
.options{
    width: 100%;
    margin-bottom: calc(2 * var(--basey));

    display: flex;
}
.option{
    width: calc(20 * var(--basex));
}
.option:first-child{
    margin-right: calc(2 * var(--basex));
}
.table{
    width: 100%;
    padding: calc(4 * var(--basex));
}
.tab{
    width: 100%;
    background-color: #F8F9FA;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    width: 100%;
    background-color: white;
    padding:calc(3 * var(--basex)) calc(2 * var(--basex));
}
.schedule{
    margin-bottom: calc(3 * var(--basey));
}
.cta{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 70px;
    background-color: var(--green);
    color: white;
    height: 40px;
    cursor: pointer;
}