.container{
    /* color: #E4E9F0; */
    background-color:#f5f5f5 ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    
}
.content{
    display: flex;
}
.box{
    width: 25%;
    border-radius: 25px;
    overflow: hidden;
    background-color: white;
}
.box:not(:last-child){
    margin-right: 2.5%;
}
@media only screen and (max-width:900px){
    .container{
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
    .content{
        flex-wrap: wrap;
        justify-content: center;
    }
    .box{
        width: 25%;
        border-radius: 25px;
        overflow: hidden;
        background-color: white;
    }
    .box:not(:nth-child(4),:nth-child(5)){
        margin-bottom: calc(4 * var(--basey));
    }
    .box:nth-child(3){
        margin-right: 0;
    }
    .box:not(:nth-child(3),:last-child){
        margin-right: 12%;
    }
   
}
@media only screen and (max-width:600px){
    .container{
        padding: calc(8 * var(--basey)) calc(2 * var(--basex)) calc(8 * var(--basey)) calc(2 * var(--basex));
    }
    .content{
        flex-direction: column;
    }
    .box{
        width: calc(32 * var(--basey));
        border-radius: 25px;
        overflow: hidden;
        background-color: white;
        margin-bottom: calc(4 * var(--basey));
    }
    .box:not(:nth-child(3),:last-child){
        margin-right: 0;
    }
}