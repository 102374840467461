/* custom-styles.css */
.ck.ck-reset.ck-editor { 
width: 100%;
overflow: auto;
margin: 10px;
 }
/* .ck-blurred.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline{
height: 30vh;
} */
.ck-editor__editable {
    min-height: 150px;
    overflow-y: scroll;
}

.ck.ck-content:not(.ck-comment__input *) {
    height: 150px;
    overflow-y: auto;
    min-height: 200px;
}
.ck-placeholder {
    color: #999; /* Example: gray color for the placeholder */
}