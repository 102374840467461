.container{
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;


    background-color: #eaeaea;
    padding: calc(1 * var(--basey));
}
.heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.inp{
    display: flex;
    justify-content: center;
    align-items: center;
}
.inp input{
    width: calc(25 * var(--basex));
    height: calc(3 * var(--basey));
}
.close{
    font-size: calc(2 * var(--basex));
    display: flex;
    justify-content: center;
    align-items: center;
}
.close svg{

}