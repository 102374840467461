.container{
    width: 100%;
    background-color: white;
    padding: calc(2 * var(--basey)) calc(2 * var(--basex));
    display: flex;
    flex-direction: column;

    z-index: 10;
}
.heading{
    width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: calc(2 * var(--basex));
    font-weight: 500;

    margin-bottom: calc(2 * var(--basey));
}   
.content{
    width: 100%;

    display: flex;
    margin-bottom: calc(2 * var(--basey));
}
.close{
    width: 100%;
    text-align: right;
    font-size: calc(2 * var(--basex));

    cursor: pointer;
}
.inner{
    width: 100%;

    display: flex;
    flex-direction: column;
}
.inner h3{
    margin-bottom: calc(1 * var(--basey));
    font-weight: 500;
}
.add_question{
    width: 100%;

    margin-bottom: calc(2 * var(--basey));
}
.aq_text{
    width: 100%;
}
.aq_inp{
    width: 100%;
}
.aq_inp textarea{
    width: 100% !important;
    height: calc(8 * var(--basey)) !important;
}
.select_answer{
    display: flex;
    width: 100%;
    align-items: center;
    
    margin-bottom: calc(4 * var(--basey));

    /* justify-content: space-between; */
}
.sa_text{
    margin-right: calc(2 * var(--basex));
    width: 175px;
}
.sa_inp{
    width: 200px;
}
.sa_inp select{
    width: calc(16 * var(--basex));
    height: calc(3 * var(--basey));
}
.sa_inp input{
    height: calc(4 * var(--basex));
}
.options{

}
.o_text{
    font-weight: 500;
    font-size: calc(1.7 * var(--basex));
    width: 100%;
    text-align: center;
    margin-bottom: calc(2 * var(--basey));

}
.o_sub-text{
    margin-bottom: calc(2 * var(--basey));
}
.o_sub-text--content{
    width: 30%;
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: calc(.8 * var(--basey)) 0;
}
.option{
    width: 100%;
    margin-bottom: calc(1 * var(--basey));
}
.cta{

}
.btn{
    border: 1px solid var(--green);
    background-color: var(--green);
    color:white;

    padding: calc(.8 * var(--basey)) 0;
    text-align: center;

    cursor: pointer;
}