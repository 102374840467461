.container {
    width: 100%;
    padding: calc(2.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    font-size: calc(4 * var(--basex));
    margin-bottom: calc(3 * var(--basey));
    border-bottom: 2px solid var(--green);
}

.content {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: space-between;
    margin-bottom: calc(4 * var(--basey));
}

.content__odd .img {
    margin-right: calc(6 * var(--basex));
}

.content__even .details {
    margin-right: calc(6 * var(--basex));
}

.img {
    flex: 1;
    min-width: 250px;
    max-width: 350px;
    margin-bottom: calc(2 * var(--basey));
}

.img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 25px;
}

.details {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc(2 * var(--basey));
}

.details h4 {
    margin-bottom: calc(2 * var(--basey));
    font-size: calc(2.4 * var(--basex));
    color: var(--green);
}

.details p {
    font-size: calc(1.8 * var(--basex));
}

.accordion {
    width: 100%;
  }
  
  .question {
    font-weight: bold;
    min-height: 56px;
    display: flex;
    align-items: center;
  }
  
  .answer {
    padding: 10px 0;
  }
@media only screen and (max-width: 1200px) {
    .content {
        width: 100%;
    }
    .content__odd .img,
    .content__even .details {
        margin-right: calc(12 * var(--basex));
    }
}

@media only screen and (max-width: 900px) {
    .container {
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
    .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 90%;
    }
    .content__odd .img,
    .content__even .details {
        margin-right: 0;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: calc(8 * var(--basey)) calc(2 * var(--basex)) calc(8 * var(--basey)) calc(2 * var(--basex));
        text-align: center;
    }
    .content {
        width: 100%;
    }
}

 /* Loader styles */
 .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
  }
  