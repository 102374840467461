
.container{
    width: 100%;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(1.5 * var(--basey)) calc(15 * var(--basex));
    /* color: #E4E9F0; */
    background-color: #f5f5f5;

    display: flex;
    flex-direction: column;
}
.upper{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.section{
    width: 33%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.section h5{
    font-size: calc(2 * var(--basex));
    margin-bottom: calc(2 * var(--basey));
}
.details{
    margin-bottom: calc(4 * var(--basey));
}
.details div{
     margin-bottom: calc(1 * var(--basey));
}
.lower{
    display: flex;
    justify-content: center;
    align-items: center;
}
.detail_links{
    display: flex;
    justify-content: center;
    align-items: center;
}
.detail_links a{
    color: black;
    text-decoration: none;
}
@media only screen and (max-width:900px){
    .container{
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
    .section{
        width: 100%;
    }
}
@media only screen and (max-width:600px){
    .container{
        padding: calc(8 * var(--basey)) calc(2 * var(--basex)) calc(8 * var(--basey)) calc(2 * var(--basex));
        text-align: center;
    }
    .upper{
        flex-direction: column;
        align-items: center;
    }
}