.container{
    padding: calc(1 * var(--basex));
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.data{
    margin-bottom: calc(1 * var(--basex));
    display: flex;
    justify-content: space-between;
}
.qcontent{

}