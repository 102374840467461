.image-container{
    width: 80vw;
    max-height: auto;
    margin: 0px auto;
}

@media screen and (max-width:500px){
    .image-container{
        width: 100vw;
    }
}