.container{
    width: 100%;
    display: flex;
    /* text-align: left; */
    padding: calc(2 * var(--basex));
    background-color: #f5f5f5;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}
.content img{
    width: calc(10 * var(--basex));
    align-self: center;
}
.content h6{
    align-self: center;
    font-weight: 600;
    font-size: calc(2 * var(--basex));
    margin-bottom: calc(.4 * var(--basey));
}
.content ul{
    list-style: none;
    min-height: calc(11 * var(--basey));
    /* margin-left: calc(2 * var(--basex)); */
}
.content ul li{
    margin-bottom: calc(.2 * var(--basey));
    font-size: calc(1.4 * var(--basex));
    margin-left: -32px;
} 