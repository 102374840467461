/* Blog.module.css */
.container {
    padding: 20px;
    padding-bottom: 0px;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  @media (max-width: 768px) {
    .cardContainer {
        flex-direction: column;
    }
}
  
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
  }
  
  .cardTitle {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .cardContent {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .readMore {
    color: #007bff;
    display: flex;
    justify-content: right;
    text-decoration: none;
  }
  
  .showMore {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
  }
  