.container{
    width: 100%;
    height: 100vh;
}
.content{
    width: 100%;
    height: 100%;

    display: flex;
}
.sidebar{
    width: 20%;
    height: 100%;

    background-color: var(--green);
    color:white;

    overflow: hidden;
    overflow-y: auto;
}
.main{
    width: 80%;
    height: 100%;
}
.top{
    display: none;
    visibility: hidden;
}

@media only screen and (max-width:600px){
    .sidebar{
        width: 0;
    }
    .main{
        width: 100%;
    }

    .top{
        display: block;
        visibility: visible;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--green);
        color: white;

        height: 5vh;
    }
}