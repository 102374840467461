.container{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to right, rgba(0,0,0,.4),rgba(0,0,0,.4));

    position: absolute;
    top:0;
    left: 0;
}

.content{
    position: absolute;
    top:50%;
    left: 50%;

    transform: translate(-50%,-50%);

    background-color: white;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.close{
    position: absolute;
    top: 10%;
    right: 5%;
}