.container {
    background-color: #fFf;
    color: var(--green);
    display: flex;
    /* box-shadow: 0px 0px 15px 5px var(--green); */
    z-index: 100;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #E4E9F0;
align-items: center;
}

.left {
    padding: calc(1 * var(--basey)) 0 calc(1 * var(--basey)) calc(15 * var(--basex));
    display: flex;
    align-items: center;
    width: 50%;
    text-wrap: nowrap;
}

.left img {
    width: calc(6 * var(--basex));
    margin-right: calc(2 * var(--basex));
}

.left h3 {
    /* color: #E4E9F0; */
}

.right {
    padding: calc(1 * var(--basey)) calc(15 * var(--basex)) calc(1 * var(--basey)) 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
    /* background-color: var(--green); */
}

.btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: calc(1.3 * var(--basex));
    font-weight: 500;
}

.btn {
    padding: calc(.8 * var(--basex)) calc(2.5 * var(--basex));
    border: 1px solid black;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;
}

.btn p{
    margin: 0px;
    padding: 0px;
}

.btn:hover {
    border-radius: 25px;
}

.btn:first-child {
    margin-right: calc(1.5 * var(--basex));
}

.btn:last-child {
    display: none;
}

.btn svg {
    font-size: calc(1.8 * var(--basex));
}

.btn p {
    margin-left: calc(.5 * var(--basex));
}

.mobile-btn{
    display: none;
}

@media only screen and (max-width:1600px) {
    .left {
        padding: calc(1 * var(--basey)) 0 calc(1 * var(--basey)) calc(8 * var(--basex));
    }

    .right {
        /* background-color: #E4E9F0; */
        padding: calc(1 * var(--basey)) calc(8 * var(--basex)) calc(1 * var(--basey)) 0;
    }

    .btn:not(:last-child) {
        display: none;
    }

    .btn:last-child {
        display: block;
    }

    .mobile-btn{
        display: flex;
        gap: 20px;
        align-items: center;
    }
}

@media only screen and (max-width:600px) {
    .left {
        padding: calc(1 * var(--basey)) 0 calc(1 * var(--basey)) calc(2 * var(--basex));
        width: 60%;
    }

    .right {
        width: 40%;
        padding: calc(1 * var(--basey)) calc(2 * var(--basex)) calc(1 * var(--basey)) 0;
    }
}