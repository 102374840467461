.container{
    /* text-align: center; */
    padding: 20px 70px;
}

.container h1{
    margin-bottom: 8px;
    margin-top: 5px;
}
.container p{
    margin-bottom: 5px;
}