.container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;

    padding: calc(5 * var(--basey)) calc(6 * var(--basex));

    background-color: #eaeaea;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.headings {
    height: 5vh;
}
.table{
    width: 100%;
    padding: calc(4 * var(--basex));
}
.inner{
    width: 100%;
    height: 100%;
    margin-bottom: calc(6 * var(--basey));
}