
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex)); */
}
.heading {
    font-size: calc(var(--basex) * 4);
    margin-bottom: calc(var(--basey) * 4);
    border-bottom: 2px solid var(--green);
}
.content{
    display: flex;
    width: 100%;
}
.img{
    padding: calc(7.5 * var(--basey)) 0 calc(7.5 * var(--basey)) calc(15 * var(--basex));
    width: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: calc(60 * var(--basey));
}
.img img{
    /* width: calc(50 * var(--basex)); */
}
.details{
    width: 50%;
    
    /* background-color: var(--green); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* color: #E4E9F0; */
    text-align: center;
    /* padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) 0; */
}
.detail{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media only screen and (max-width:1200px){
    /* .detail{
        width: 50%;
    } */
    .detail >div{
        width: 100%;
    }
    .content{
        min-height: 600px;
    }
}
@media only screen and (max-width:900px){
    .content{
        flex-direction: column;
        min-height: 0px;
    }
    .img{
        width: 100%;
    }
    .details{
        width: 100%;
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
}
@media only screen and (max-width:600px){
    .img{
        min-height: calc(40 * var(--basey));
    }
    .detail{
        width: 100%;
    }
    .details{
        padding: calc(4 * var(--basey)) calc(2 * var(--basex)) calc(6 * var(--basey)) calc(2 * var(--basex));
    }
}