.container{
    width: 100%;
    height: 100%;
    border-top-left-radius: 25% 50%;
    border-bottom-left-radius: 25% 50%;
    border-top-right-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;

    background-color: #000;

    position: relative;
}
.content{
    width: 50%;
    height: 100%;
    background-color: #eaeaea;
    border-top-left-radius: 25% 50%;
    border-bottom-left-radius: 25% 50%;
    border-top-right-radius: 25% 50%;
    border-bottom-right-radius: 25% 50%;

    position: absolute;
    left: 0;
    transition: all .25s;
}
.on{
    left: auto;
    right: 0;
}
.on-container{
    background-color: green;
}