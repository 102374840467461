.container{
}
.content{
    display: flex;
    flex-direction: column;
}
.brand{
    display: flex;
    /* justify-content: center; */
    align-items: center;

    padding: calc(4 * var(--basey)) calc(4 * var(--basex));

}
.brand img{
    height: calc(5 * var(--basey));
    margin-right: calc(1 * var(--basex));
}
.brand h2{

}
.nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    width: 100%;
}
.items{
    /* border:1px solid white;
    border-right: none;
    border-left: none; */

    padding: calc(2 * var(--basey)) calc(4 * var(--basex));
    width: 100%;

    cursor: pointer;
}
.items:hover:not(.active){
    background-color: rgb(3, 63, 65);
    color: white;
}

.active{
    background-color:#eaeaea;
    color: var(--green);
    font-weight: 600;
}