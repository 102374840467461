.container{
    width: 100%;
    height: 100vh;
    background-color: #eaeaea;
    overflow: scroll;
    overflow-x: hidden;

    position: relative;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: calc(6 * var(--basey)) calc(6 * var(--basex));

}
.headings {
    height: 10vh;
}
.headings h1{

}
.inner{
    width: 100%;
    padding: calc(4 * var(--basex)) calc(4 * var(--basex)) calc(0 * var(--basex)) calc(4 * var(--basex));
}
.upper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: calc(4 * var(--basex)); */
}
.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.left{
    display: flex;
    align-items: center;
}
.category{
    margin-right: calc(2 * var(--basex));
    height: calc(4 * var(--basey));
    width: calc(18 * var(--basex));
}
.category select{
    
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
}
.right{

}
.right div{
    width: calc(18 * var(--basex));
    height: calc(4 * var(--basey));
    text-align: center;

    background-color: var(--green);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.lower{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: calc(4 * var(--basex));
}
.answer{
    width: 100%;
    /* justify-content: center; */
}
.btn{
    position: fixed;
    bottom:10px;
    right: 70px;
    z-index: 10;

    background-color: var(--green);
    color: white;

    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    transition: all .2s;
    cursor: pointer;
}
.btn:hover{
    transform: scale(1.1);
}

@media only screen and (max-width:600px){
    .headings{
        height: 5vh;
    }
    .content{
        padding: calc(5 * var(--basey)) calc(2 * var(--basex));
    }
    .left{
        flex-direction: column;
        width: 100%;
    }
    .category{
        width: 100%;
        margin-bottom: 30px;
    }
    .lower{
        padding: calc(2 * var(--basey)) calc(2 * var(--basex));
    }
    .btn{
        height: 100px;
        width: 100px;
        right: calc(50% - 50px);
    }
    
}
