.question_box{
    width: 100%;
    background-color: white;
    margin-bottom: calc(2 * var(--basey));
}
.btn{
    position: fixed;
    bottom:30px;
    right: 70px;
    z-index: 10;

    background-color: var(--green);
    color: white;

    height: 80px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    transition: all .2s;
    cursor: pointer;
}
.btn:hover{
    transform: scale(1.1);
}