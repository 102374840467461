
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* box-shadow: 0px 0px 15px 0px var(--green); */
    /* border: 1px solid var(--green); */
    border-radius: 20px;
    background-color: white;
    /* color:#E4E9F0; */
    padding: calc(2.5 * var(--basey)) calc(2.5 * var(--basex));
}
.content{
   display: flex;
   flex-direction: column;
}
.profile{
    display: flex;
    align-items: center;
}
.img{
    border-radius: 50%;
    width: calc(12 * var(--basex));
    height: calc(12 * var(--basey));
    overflow: hidden;
    margin-right: calc(2 * var(--basex));
    /* border: 1.5px solid var(--green); */
    margin-bottom: calc(2 * var(--basey));
}
.img img{
    
    width: inherit;
}
.details{

}
.details h6{
    font-size: calc(2 * var(--basex));
    /* color: var(--green); */
}
.details p{
    font-size:calc(1.2 * var(--basex));
    /* color: var(--green); */
}
.testimonials{

}