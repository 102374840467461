.inner{
    width: 100%;

    display: flex;
    flex-direction: column;
}
.inner h3{
    margin-bottom: calc(1 * var(--basey));
    font-weight: 500;
}
.add_question{
    width: 100%;

    margin-bottom: calc(2 * var(--basey));
}
.aq_text{
    width: 100%;
}
.aq_inp{
    width: 100%;
}
.aq_inp input{
    width: 100% !important;
    height: calc(4 * var(--basey)) !important;
}
.select_answer{
    display: flex;
    width: 100%;
    align-items: center;
    
    margin-bottom: calc(4 * var(--basey));

    /* justify-content: space-between; */
}
.sa_text{
    margin-right: calc(3 * var(--basex));
}
.sa_inp{

}
.sa_inp select{
    width: calc(16 * var(--basex));
    height: calc(3 * var(--basey));
}
.options{

}
.o_text{
    font-weight: 500;
    font-size: calc(1.7 * var(--basex));
    width: 100%;
    text-align: center;
    margin-bottom: calc(2 * var(--basey));

}
.o_sub-text{
    margin-bottom: calc(2 * var(--basey));
}
.o_sub-text--content{
    width: 30%;
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: calc(.8 * var(--basey)) 0;
}
.option{
    width: 100%;
    margin-bottom: calc(1 * var(--basey));
}