.container{
    width: 100%;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    /* display: flex; */
    /* justify-content: center; */
    text-align: center;
}
.heading{
    width: 100%;
    display: flex;
    justify-content: center;
}

.heading h2{
    font-size: calc(4 * var(--basex));
    margin-bottom: calc(6 * var(--basey));
    border-bottom: 2px solid var(--green);
    width: fit-content;
}
.cards{
    width: 100%;
}
.card{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardcontain{
    width: 80%;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 auto;
}
@media only screen and (max-width:1250px){
    .container{
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
}
@media only screen and (max-width:600px){
    .container{
        padding: calc(8 * var(--basey)) calc(4 * var(--basex)) calc(8 * var(--basey)) calc(4 * var(--basex));
    }
}