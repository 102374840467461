/* AllBlogs.module.css */
.container {
    padding: 20px;
    padding-top: 100px;
    background-color: #f9f9f9;
    min-height: 100vh;
    position: relative; /* Added to position loader */
  }
  
  .heading {
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
    color: #333;
  }
  
  .cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .card {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 450px; /* Adjust max-width as needed */
    margin: 0 auto; /* Center align the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .cardTitle {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: normal;
    color: #000;
  }
  
  .cardContent {
    font-size: 16px;
    margin-bottom: 15px;
    color: #555;
  }
  
  .readMore {
    display: flex;
    color: #007bff;
    text-decoration: none;
    font-weight: normal;
justify-content: right;
    margin-top: 10px;
  }
  
  .readMore:hover {
    text-decoration: underline;
  }
  
  /* Loader styles */
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: bold;
    color: #007bff;
  }
  