.container{
    width: 100%;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
    background-color: #f5f5f5;
}
.container h2{
    font-size: calc(4 * var(--basex));
    margin-bottom: calc(6 * var(--basey));
    border-bottom: 2px solid var(--green);
}
.content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cards{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    border-radius: 15px;
    width: 30%;
    height: 100%;
    /* background-color: var(--green); */
    /* color: #E4E9F0; */
    padding: calc(3 * var(--basey)) calc(2 * var(--basex)) calc(3 * var(--basey)) calc(2 * var(--basex));
}
.card:not(:last-child){
    margin-right: 4.5%;
}
.icon{
    font-size: calc(4 * var(--basex));
    /* margin-bottom: calc(.5 * var(--basey)); */
}
.icon svg{

}
.card h4{
    font-size: calc(2.4 * var(--basex));
    margin-bottom: calc(.8 * var(--basey));
}
.card p{
    min-height: calc(var(--basey) * 8);
}
@media only screen and (max-width:1400px){
    .cards{
        width: 90%;
    }
    .card{     
        width: 31.5%;
    }
    .card:not(:last-child){
        margin-right: 2.25%;
    }
}
@media only screen and (max-width:1200px){
    
    .cards{
        width: 100%;
    }
    .card p{
        min-height: calc(var(--basey) * 12);
    }
}
@media only screen and (max-width:900px){  
    .cards{
        flex-direction: column;
    }
    .card{
        width: calc(32 * var(--basey));
    }
    .card:not(:last-child){
        margin-right: 0%;
        margin-bottom: calc(2 * var(--basey));
    }
}
@media only screen and (max-width:600px){  
    .container{
        padding: calc(8 * var(--basey)) calc(2 * var(--basex)) calc(8 * var(--basey)) calc(2 * var(--basex));
    }
}