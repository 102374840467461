.container{
    display: flex;
    flex-direction: column;

    padding:calc(1 * var(--basey)) calc(1 * var(--basey));
    border: 1px solid black;
}
.heading{
    font-weight: 500;
    margin-bottom: calc(1 * var(--basey));
}
.questions{
    display: flex;
}
.sub_heading{
    font-weight: 500;
    margin-right: calc(1 *var(--basex));
    margin-bottom: calc(1 * var(--basex));
}
.content{

}
.options{
    margin-bottom: calc(1 * var(--basey));
}
.option{
    display: flex;
}
.sub_oheading{
    margin-right: calc(1 *var(--basex));
}
.cta{
    display: flex;
    margin-bottom: calc(1 * var(--basey));
    margin-top: calc(1 * var(--basey));
}
.remove{
    margin-right: calc(1 *var(--basex));

}
.remove div{
   color: var(--green);
   padding: calc(.5 * var(--basey))  calc(1 * var(--basex));
}
.edit div{
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    padding: calc(.5 * var(--basey))  calc(1 * var(--basex));

    cursor: pointer;
}
.form{
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    overflow: scroll;
    overflow-x: hidden;

    display: flex;
    justify-content: center;
    padding: calc(8 * var(--basey)) 0;
    /* align-items: center; */
}
.form_inner{
    width: 50vw;
    margin-bottom: calc(8 * var(--basey));
    /* height: 70vh; */
}
.remove,.edit{
    cursor: pointer;
}