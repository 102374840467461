.container{
    width: 100%;
    background-color: white;
    padding: calc(2 * var(--basex));
}
.content{

}
.inner-card{
    padding: 10px;
    border: 1px solid var(--green);
    margin-bottom: calc(1 * var(--basey));
}
.data-contain{
    display: flex;
    padding: 10px;
    border: 1px solid var(--green);
    margin-bottom: calc(2 * var(--basey));
    /* justify-content: space-evenly; */

}
.data{
    display: flex;
    flex-direction: column;
}
.data:first-child{
    margin-right: calc(50 * var(--basex));
}
.cta{
    display: flex;
    margin-bottom: calc(1 * var(--basey));
    margin-top: calc(1 * var(--basey));
}
.remove{
    margin-right: calc(1 *var(--basex));

}
.remove div{
   color: var(--green);
   padding: calc(.5 * var(--basey))  calc(1 * var(--basex));
   cursor: pointer;
}
.edit div{
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    padding: calc(.5 * var(--basey))  calc(1 * var(--basex));

    cursor: pointer;
}
.form{
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    overflow: scroll;
    overflow-x: hidden;

    display: flex;
    justify-content: center;
    padding: calc(8 * var(--basey)) 0;

    z-index: 10;
    /* align-items: center; */
}
.form_inner{
    width: 50vw;
    margin-bottom: calc(8 * var(--basey));
    /* height: 70vh; */
}