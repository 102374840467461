.container{
    width: 100%;
    height: 100%;
}
.content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.content input{
    height: 100%;
    width: 15%;

    font-size: calc(2 * var(--basex));
    text-align: center;
}
/* Chrome, Safari, Edge, Opera */
.content input::-webkit-outer-spin-button,
.content input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.content input[type=number] {
  -moz-appearance: textfield;
}