
.container{
    width: 100%;
    padding: calc(7.5 * var(--basey)) calc(15 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    /* background-color: #E4E9F0; */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;
}
.heading{
    font-size: calc(4 * var(--basex));
    margin-bottom: calc(6 * var(--basey));
    border-bottom: 2px solid var(--green);
}
.cardbox{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cards{
    /* display: flex; */
    width: 100%;
}
.card{
    /* width: 30%; */
    /* margin:0 1.5%;
    box-shadow: 0px 0px 25px 0px var(--green);
    border-radius: 20px;
    background-color: var(--green);
    color:#E4E9F0; */
    padding:5%;
}
@media only screen and (max-width:1250px){
    .container{
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
}
@media only screen and (max-width:600px){
    .container{
        padding: calc(8 * var(--basey)) calc(4 * var(--basex)) calc(8 * var(--basey)) calc(4 * var(--basex));
    }
}