*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Poppins, 'Open Sans';
}

:root {
  --basex: 10px;
  --basey: 10px;
  --green: rgb(4, 104, 108);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--green);
  border-radius: 10px;
}

@media only screen and (max-width:1250px) {
  :root {
    --basex: 9px;
    --basey: 9px;
  }
}

@media only screen and (max-width:1050px) {
  :root {
    --basex: 8px;
    --basey: 8px;
  }
}

select option:hover {
  box-shadow: 0 0 10px 100px #1882A8 inset;
}

@font-face {
  font-family: "'Tiro Devanagari Hindi', serif";
  src: url("https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi&display=swap") format("truetype");
}



/* some genric styles */
/* .it-banner-image {
  text-align: center;
}

 .it-banner-image.owl-theme .owl-nav {
  margin: 0;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.it-banner-image.owl-theme .owl-nav .owl-prev, .it-banner .it-banner-image.owl-theme .owl-nav .owl-next {
  background-color: #fff;
  color: #0000ff;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

 .it-banner-image.owl-theme:hover .owl-prev, .it-banner .it-banner-image.owl-theme:hover .owl-next {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.owl-carousel { 
  direction: ltr;
}
.owl-carousel .owl-item { 
  direction: rtl; 
}

@media only screen and (max-width:767px){
  .it-banner-image, .seo-banner-image, .seo-banner .it-banner-image, .seo-banner .seo-banner-image {
    margin-top: 30px;
  }
  .it-banner-image.owl-theme .owl-nav .owl-prev, .it-banner-image.owl-theme .owl-nav .owl-next {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  .projects-slides.owl-theme .owl-nav.disabled + .owl-dots {
    margin-top: 0;
  }
  .projects-slides.owl-theme .owl-dots .owl-dot span {
    margin: 0 4px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) 
{
  .it-banner-image,  .seo-banner-image, .seo-banner .it-banner-image, .seo-banner .seo-banner-image {
    margin-top: 40px;
  }
  .it-banner-image.owl-theme .owl-nav .owl-prev,  .it-banner-image.owl-theme .owl-nav .owl-next {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .seo-banner-slider.owl-theme .owl-nav .owl-prev {
    top: auto;
    bottom: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .seo-banner-slider.owl-theme .owl-nav .owl-next {
    top: auto;
    bottom: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .machine-learning-slider.owl-theme .owl-nav .owl-prev {
    left: 15px;
    top: auto;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    width: 35px;
    height: 35px;
    bottom: 15px;
  }
  .machine-learning-slider.owl-theme .owl-nav .owl-next {
    right: 15px;
    top: auto;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    width: 35px;
    height: 35px;
    bottom: 15px;
  }
  .partner-slides.owl-theme .owl-nav .owl-prev, .partner-slides.owl-theme .owl-nav .owl-next {
    left: -5px;
    display: block;
    font-size: 10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
  }
  .partner-slides.owl-theme .owl-nav .owl-next {
    left: auto;
    right: -5px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
   .it-banner-image.owl-theme .owl-nav .owl-prev,  .it-banner-image.owl-theme .owl-nav .owl-next {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .partner-slides.owl-theme .owl-nav .owl-prev, .partner-slides.owl-theme .owl-nav .owl-next {
    left: -10px;
    display: block;
    font-size: 10px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
  }
  .partner-slides.owl-theme .owl-nav .owl-next {
    left: auto;
    right: -10px;
  }
  .seo-banner-slider.owl-theme .owl-nav .owl-prev, .seo-banner-slider.owl-theme .owl-nav .owl-next {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
} */


@media print {

  html,
  body {
    height: 90vh !important;
    margin: 10px !important;
  }
}

.content-container img {
  width: 80vw;
  max-height: auto;
  margin: 0px auto;
}

@media screen and (max-width:500px) {


  .content-container img {
    width: 100vw;
  }

}