.container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;


    background-color: #eaeaea;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: calc(5 * var(--basey)) calc(6 * var(--basex));
}
.headings {
    height: 5vh;
}
.headings h1{

}
.options{
    width: 100%;
    margin-bottom: calc(2 * var(--basey));
    display: flex;
}
.option{
    width: calc(20 * var(--basex));
    margin-right: calc(1 * var(--basex));
}
.option input{
    height: 100%;
    padding: 10px;

}
.table{
    width: 100%;
    height: 80vh;
    padding: calc(4 * var(--basex));
}
.inner{
    width: 100%;
    height: 100%;
    margin-bottom: calc(6 * var(--basey));
}
.back{
    width: 100%;
    margin-bottom: calc(2 * var(--basey));
}
.close{
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--green);
    color: white;

    border-radius: 50%;

    cursor: pointer;
}
.scontent{
    width: 100%;
}
.userdetails{
    width: 100%;
    background-color: white;
    /* border-radius: 15px; */

    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: left;

    padding: calc(2 * var(--basey));
}
.userdetails{
    margin-bottom: calc(2* var(--basey));
}
.ud_content{
    width: 100%;
    display: flex;
}
.ud_heading{
    width: 100%;
    margin-bottom: calc(1 * var(--basex));
}
.data{
    width: 100%;

    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}
.data span{
    display: flex;
    margin-bottom: calc(.5 * var(--basey));
    /* flex-direction: column; */
}
.data span b{
    margin-right: calc(1 * var(--basex));
}
.data span:first-child{
    /* font-weight: 500; */
}
.pc_data{
    width: 100%;
}
.pc_head{
    width: 100%;
    margin-bottom: calc(.5 * var(--basey));
}
.tags{
    display: flex;
    flex-wrap: wrap;
}
.tag{
    border: 2px solid var(--green);
    padding: calc(0 * var(--basey)) calc(1 * var(--basex));
    margin-right: calc(1 * var(--basex));
    margin-bottom: calc(.5 * var(--basey));

    border-radius: 15px;
    font-size: calc(1.4 * var(--basex));

    display: flex;
    justify-content: center;
    align-items: center;
}
.m_heading{
    width: 100%;
    /* background-color: #F8F9FA; */
    text-align: center;
    margin-bottom: calc(3 * var(--basex));
}

@media only screen and (max-width:600px){
    .content{
        padding: calc(5 * var(--basey)) calc(2 * var(--basex));
    }
    .table{
        padding: calc(4 * var(--basex)) calc(1.3 * var(--basex));
    }
    .ud_content{
        flex-direction: column;
    }
}