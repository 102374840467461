
.container{
    display: flex;
    width: 100%;
    min-height: 600px;
    margin-top:60px;
}
.left{
    padding: calc(7.5 * var(--basey)) calc(5 * var(--basex)) calc(7.5 * var(--basey)) calc(15 * var(--basex));
    width:50%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.content h1{
    font-size: calc(3 * var(--basex));
    margin-bottom: calc(2 * var(--basey));
}
.content p{
    font-size: calc(1.4 * var(--basex));
    margin-bottom: calc(3 * var(--basey));

}
.btns{
    display: flex;
    font-size: calc(1.4 * var(--basex));
    font-weight: 500;
}
.btns div{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--green);
    border-radius: 10px;
    padding: calc(1 * var(--basex)) calc(1.5 * var(--basex));
    transition: all .5s;
}
.btns div:hover{
    border-radius: 25px;
}
.active{
    background-color: var(--green);
    color: white;
    margin-right: calc(2 * var(--basex));
}
.passive{

}

.right{
    /* display: flex; */
    position: relative;
    /* background-color: var(--green); */
    padding: calc(7.5 * var(--basey)) 0 calc(7.5 * var(--basey)) 0;
    width: 50%;
    overflow: hidden;
}
.contain{
    margin-top: calc(1 * var(--basey));
    width: 100%;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.imgslider{
    
}
.right img{
    /* width: 100%; */
    /* height: calc( 60 * var(--basey)); */
    height: 600px;
    opacity: 1;
    filter: grayscale(.1);
}
.imgslider{
    width: 80%;
}
.imgbox{
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
}
.imgbox p{
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    background-color: #E4E9F0;
    color: var(--green);
    z-index: 99;
    top: 80%;
    padding: calc(1 * var(--basey)) 0;
}
/* .docterimg{
    height: auto;
} */
/* .right img{
    position: absolute;
    height: calc(55 * var(--basey));
    bottom: 0;
    left: calc(-10 * var(--basex));
} */
/* .it-banner-image{
    height: 500px;
}
.imgbox{
    height: 500px;
} */
.imgbox img{
    /* height: 500px; */
    margin: 0 auto;
}
.imgbox p{

}
@media only screen and (max-width:1200px){
    .content{
        width: 90%;
    }
}
@media only screen and (max-width: 900px) {
    .container{
        min-height: 400px;
        flex-direction: column-reverse;
        
    }
    .contain{
        position: relative;
        transform: none;
        top: 0;
        left: 0;
    }
    .left{
        width: 100%;
        text-align: center;
        padding: calc(8 * var(--basey)) calc(8 * var(--basex)) calc(8 * var(--basey)) calc(8 * var(--basex));
    }
    .contain{
        margin-top: 0;
    }
    .content{
        justify-content: center;
        align-items: center;
    }
    .right img{
        height: 350px;
    }
    .content{
        width: 100%;
    }
    .right{
        width: 100%;
        padding: 0;
    }
    .right img{
        /* display: none; */
        /* visibility: hidden; */
    }
}
@media only screen and (max-width: 600px) {
    .left{
        width: 100%;
        padding: calc(4 * var(--basey)) calc(4 * var(--basex)) calc(6 * var(--basey)) calc(4 * var(--basex));
    }
    .content h1{
        font-size: calc(3.15 * var(--basex));
        margin-bottom: 32px;
        /* margin-bottom: calc((4 * var(--basey))); */
    }
    .right img{
        height: 250px;
    }
    .content p{
        font-size: calc(2 * var(--basex));
    }
    .btns{
        font-size: calc(2 * var(--basex));
        flex-direction: column;
        width: 100%;
    }
    .active{
        margin-right: 0;
        margin-bottom: calc(2 * var(--basex));
    }
}
@media only screen and (max-width: 500px) {
    .img{
        height: calc(13 * var(--basey));
        width: calc(13 * var(--basex));
    }
}