.container{
    width: 100%;
}
.content{
    width: 100%;

    display: flex;
    /* justify-content: space-between; */
}
.data{
    display: flex;
    /* justify-content: center; */
    align-items: center;

    min-width: 150px;
    margin-right: calc(3 * var(--basex));
    margin-bottom: calc(2 * var(--basex));


}
.rightm{
    margin-right: calc(12 * var(--basex));

}
.item{
    margin-right: calc(1 * var(--basex));
    font-weight: 500;
}
.item input{
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    text-align: center;
}
.item input::-webkit-outer-spin-button,
.item input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.checkbox{
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: 1px solid var(--green);
}
.check{
    background-color: var(--green);
}
.daily{
    width: 100%;
    display: flex;
    justify-content: space-around;
}