.container{
    width: 100%;
    /* height: 100%; */
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(35 * var(--basex));
    color: var(--green);
    background-color:white;
    padding: calc(4 * var(--basey)) calc(2 * var(--basex));
}
.icon{
    font-size: calc(5 * var(--basey));
    margin-bottom: calc(1 * var(--basey));
    color: var(--green);
}
.icon svg{

}
.verify_otp{
    font-size: calc(2.2 * var(--basey));
    margin-bottom: calc(.5 * var(--basey));
}
.sent{
    font-size:calc(1.2 * var(--basey));
    margin-bottom: calc(2 * var(--basey));
}
.input{
    width: 100%;
    height: calc(4 * var(--basey));
    margin-bottom: calc(3 * var(--basey));
    margin-left: calc(2 * var(--basex));
    display: flex;
    justify-content: center;
    align-items: center;
}
.resend{
    font-size:calc(1.6 * var(--basey));
    background-color: var(--green);
    color: white;
    padding: calc(1 * var(--basey)) calc(3 * var(--basey));
    margin-bottom: calc(2 * var(--basey));

    cursor: pointer;
}
.back{
    text-decoration: underline;
}
.back a{
    color: var(--green);
}