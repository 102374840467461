.container{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* background-color: var(--green); */
    /* color: #E4E9F0; */
    padding-top: calc(2 * var(--basey));
    padding-bottom: calc(2 * var(--basey));

}
.content{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.box{
    width: 25%;
}
.box h6{
    font-size: calc(2 * var(--basey));
}
.box p{
    font-size: calc(1.2 * var(--basey));
}
@media only screen and (max-width:600px){
    .container{
        padding-top: calc(10 * var(--basey));
        padding-bottom: calc(10 * var(--basey));
    }
    .content{
        flex-direction: column;
    }
    .box{
        border: 2px solid var(--green);
        padding: calc(4 * var(--basex));
        width:calc(20 * var(--basey));
        border-radius: 15px;
    }
    .box h6{
        font-size: calc(2.4 * var(--basey));
    }
    .box p{
        font-size: calc(1.6 * var(--basey));
    }
    .box:not(:last-child){
        margin-bottom: calc(6* var(--basey));
    }
}