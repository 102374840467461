.video{
    display: flex;
    justify-content: center;
    margin: 20px auto;
}
.video-container{
    width: 80vw;
    height: 80vh;
    margin: 0px auto;
}

@media screen and (max-width:500px){
    .video-container{
        width: 100vw;
        height: 30vh;
    }
}