.container{
    padding: calc(1 * var(--basey)) calc(15 * var(--basex)) calc(1 * var(--basey)) calc(15 * var(--basex));
    color: #E4E9F0;
    background-color: var(--green);
    /* color: var(--green); */
    text-align: center;
    width: 100%;
}
.container h4{
    font-size: calc(2 * var(--basex));
}
@media only screen and (max-width:900px){
    .container{
        padding: calc(1 * var(--basey)) calc(8 * var(--basex)) calc(1 * var(--basey)) calc(8 * var(--basex));
    }
}
@media only screen and (max-width:600px){
    .container{
        padding: calc(1 * var(--basey)) calc(4 * var(--basex)) calc(1 * var(--basey)) calc(4 * var(--basex));
    }
}