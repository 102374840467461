.container{ 
    background-color: white;
    width: 100%;
    padding: calc(1 * var(--basex));
    margin-bottom: 20px;
}
.heading{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
}
.answer{
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.answer > div{
    display: flex;
    align-items: center;
}
.answer input{
    margin-right: 10px;
}
.textbox{
    height: 100px;

    padding: 10px;
}

@media only screen and (max-width:600px){
    .textbox{
    width: 100%;
    }
}