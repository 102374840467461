.container{
    width: 100%;
    height: 40px;

    position: relative;
}
.main{
    width: 100%;
    height: 100%;

    background-color: var(--green);
    color: white;
    text-align: center;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 10%;

    cursor: pointer;
}
.main div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.rotate{
    transition: all .2s;
    transform: rotateZ(180deg);
}
.options{
    width: 100%;
    background-color: white;

    position: absolute;
    top: 110%;
    left: 0;
    z-index: 9;

    max-height: 150px;
    overflow: hidden;
    overflow-y: auto;
}
.option{
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    cursor: pointer;

    transition: all .2s;
}
.option:hover{
    background-color: var(--green);
    color: white;
}