
.table{
    width: 100%;
    padding: calc(4 * var(--basex));
}

.container{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;

    padding: calc(5 * var(--basey)) calc(6 * var(--basex));

    background-color: #eaeaea;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.headings {
    height: 5vh;
}
.inner{
    width: 100%;
    padding: calc(4 * var(--basex));
}
.upper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(4 * var(--basex));
}
.nav{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.left{
    display: flex;
    align-items: center;
}
.category{
    margin-right: calc(2 * var(--basex));
    height: calc(4 * var(--basey));
    width: calc(18 * var(--basex));
}
.category select{
    
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
}
.answer{

}
.answer select{
    height: calc(4 * var(--basey));
    width: calc(18 * var(--basex));
    text-align: center;
    border-radius: 3px;

}
.right{

}
.right div{
    width: calc(18 * var(--basex));
    height: calc(4 * var(--basey));
    text-align: center;

    background-color: var(--green);
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.cards{
    width: 100%;
}
.card{
    width: 100%;
    margin-bottom: calc(2 * var(--basey));
}
.form{
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.6);
    overflow: scroll;
    overflow-x: hidden;

    display: flex;
    justify-content: center;
    padding: calc(8 * var(--basey)) 0;

    z-index: 10;
    /* align-items: center; */
}
.form_inner{
    width: 50vw;
    margin-bottom: calc(8 * var(--basey));
    /* height: 70vh; */
}